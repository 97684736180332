import * as R from "ramda";
import Helmet from "react-helmet";
import BingoWave from "./images/bingo-wave.svg";
import BlueyJump from "./images/bluey-jump.svg";
import ChatterMax from "./images/chattermax.png";

import "./App.css";
import "./fonts/stylesheet.css";

function App() {
  return (
    <main className="bg-french-pass">
      <Helmet>
        <title>Lilah Suárez</title>
      </Helmet>
      <img
        src={BingoWave}
        className="bingo-bottom hidden md:block"
        alt="wave"
      />
      <div className="container mx-auto">
        <div className="fixed top-4 z-50 inset-x-0">
          <div className="max-w-3xl mx-auto px-8 md:px-12">
            <div className="w-full mx-auto">
              <div className="relative flex flex-col w-full p-3 mx-auto bg-white shadow-thick backdrop-blur-xl backdrop-filter rounded-2xl md:rounded-full md:items-center md:justify-between md:flex-row">
                <div className="flex flex-row items-center justify-between md:justify-center mx-auto">
                  <a
                    className="text-white hover:text-lime-500 items-center font-bold gap-3 inline-flex text-lg"
                    href="/"
                  >
                    <span className="px-4 normal-case text-6xl font-bluey text-sail-500">
                      Lilah
                    </span>
                    <img src={BlueyJump} className="h-16" alt="bluey jumping" />
                  </a>
                  <button className="inline-flex items-center justify-center p-2 text-zinc-400 hover:text-blue-300 focus:outline-none focus:text-white md:hidden"></button>
                </div>
                {
                  // <nav className="flex-col flex-grow hidden py-12 md:py-0 md:flex md:items-end justify-center md:flex-row">
                  //   <ul className="space-y-2 list-none md:space-y-0 md:ml-auto items-center md:inline-flex justify-center text-center md:text-left gap-3">
                  //     <li>
                  //       <a
                  //         href="/system/overview"
                  //         className="px-4 py-2 text-xl tracking-wide font-medium font-display text-purple hover:text-lime-500 focus:outline-none focus:shadow-none focus:text-trinidad-500"
                  //       >
                  //         Overview
                  //       </a>
                  //     </li>
                  //     <li>
                  //       <a
                  //         href="/system/style-guide"
                  //         className="px-4 py-2 text-xl tracking-wide font-medium font-display text-purple hover:text-lime-500 focus:outline-none focus:shadow-none focus:text-trinidad-500"
                  //       >
                  //         Style Guide
                  //       </a>
                  //     </li>
                  //   </ul>
                  // </nav>
                }
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="px-8 py-24 mx-auto md:px-12 2xl:max-w-7xl pt-48 lg:pt-32 h-[64vh] lg:min-h-screen flex items-center justify-center">
            <div className="p-8 lg:p-20 bg-purple-500 rounded-3xl lg:rounded-6xl">
              <div className="relative">
                <img
                  src={ChatterMax}
                  className="h-24 md:h-48 absolute left0 -right-8 top-0"
                  alt="chattermax"
                />
                <div className="inline-flex items-center gap-4">
                  <div className="w-20 h-1 bg-white hidden md:block"></div>
                  <span className="font-semibold tracking-wide uppercase text-white">
                    CHATTERMAX SAYS:
                  </span>
                </div>

                <p className="mt-8 text-4xl font-display font-semibold md:text-6xl lg:text-9xl text-bingo-light-500">
                  <span className="block text-[#FEF8DB]">VOTE</span>
                  LILAH SUAREZ
                  <span className="block text-white"> FOR VICE PRES</span>
                </p>
              </div>
              <div className="grid text-left grid-cols-1 gap-10 lg:grid-cols-3 lg:gap-32 lg:items-end">
                <p className="text-white text-lg mt-8 lg:col-span-2"> </p>
                <div className="flex" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="px-8 py-12 mx-auto md:px-12 2xl:max-w-7xl">
            <div className="p-8 lg:p-20 bg-white rounded-3xl lg:rounded-6xl overflow-x-hidden">
              <div className="relative">
                <div className="inline-flex items-center gap-4">
                  <div className="w-20 h-1 bg-purple-500 hidden md:block"></div>
                  <span className="text-xs font-semibold tracking-wide uppercase text-purple-500">
                    Friends.
                  </span>
                </div>
                <p className="text-4xl mt-8 font-display font-semibold md:text-6xl lg:text-8xl text-bingo-light-500">
                  Remember the
                  <span className="md:block md:text-purple-500">
                    {" "}
                    good times.
                  </span>
                </p>
              </div>
              <div className="w-[calc(256px*22)] p-6">
                <ul className="flex animate-carousel gap-4">
                  {
                    // loop through photos
                    R.range(0, 21).map((photo) => (
                      <li
                        className="relative aspect-square h-96 w-64 flex items-center justify-center"
                        index={photo}
                      >
                        <img
                          src={require(`./images/friends/${photo}.jpg`)}
                          className="h-full object-cover rounded-2xl object-center"
                        />
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default App;
